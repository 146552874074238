import React, { useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Tray, IconButton, Para, Button, Flex, Label } from 'workspace-core-ui';
import { useOverlayTriggerState } from 'react-stately';
import useTranslation from '@hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '@hooks/redux-hooks';
import getSymbol from '@utils/getSymbol';
import { GatsbyImage } from 'gatsby-plugin-image';
import { logItem } from '@slices/loggingSlice';
import ShareModalButton from './ShareModalButton';
import CustomMdxRenderer from './CustomMdxRenderer';

interface AboutTrayButtonProps {
  buttonProps?: any;
}

const AboutTrayButton = (props: AboutTrayButtonProps) => {
  const data = useStaticQuery(graphql`
    query aboutModalImage {
      aboutSymbols: allAirtable(
        filter: {
          table: { eq: "Game Elements" }
          data: { Name: { eq: "About Modal" } }
        }
      ) {
        ...GatsbyImageGetFragment
      }
    }
  `);

  const { t } = useTranslation();
  const trayTitle = t('Share Modal Header');
  const shareModalTitle = t('Share Modal Header');
  const dispatch = useAppDispatch();
  const { buttonProps } = props;
  const overlayState = useOverlayTriggerState({});
  const { hasStarted } = useAppSelector(e => e.gameState);
  const { data: aboutModalBgImg } = getSymbol(data.aboutSymbols);
  // control states

  const TrayHeader = useCallback(
    () => (
      <Flex p={[4, 6, 6]}>
        <Flex flex={1}>
          <IconButton
            data-cy="closeAboutModalIcon"
            onPress={() => overlayState.close()}
            showOutline={false}
            iconName="close"
          >
            Close
          </IconButton>
        </Flex>
        <ShareModalButton
          loggingTarget="share_modal_button_about_tray"
          modalTitle={shareModalTitle}
        />
      </Flex>
    ),
    [overlayState, shareModalTitle],
  );

  // TODO: the alt of this image can't be determined, perhaps this can be fixed later on when we have a way
  // for cms to define this for us
  const AsideImage = useCallback(
    () => <GatsbyImage image={aboutModalBgImg} alt="aside" />,
    [aboutModalBgImg],
  );

  return (
    <>
      <IconButton
        onPress={() => {
          dispatch(
            logItem({
              collection_name: 'events',
              event_type: 'click',
              target: `about modal open button`,
            }),
          );
          overlayState.open();
        }}
        data-cy="aboutButton"
        iconName="info"
        // TODO: localize ARIA
        aria-label="about"
        {...buttonProps}
      />
      <Tray
        sx={{
          // I do this to override the default mdx styles for images that may exist in the tray
          // this is pretty custom but it fits with the art direction of the tray contents
          '.gatsby-resp-image-wrapper': {
            marginLeft: '0px !important',
            width: '300px',
          },
        }}
        title={trayTitle}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        showBgImage={!hasStarted}
        HeaderComponent={TrayHeader}
        AsideImage={AsideImage}
        isDismissable
      >
        <Para as="span" variant="p3">
          <CustomMdxRenderer>{t('About Modal', true)}</CustomMdxRenderer>
        </Para>
        <Flex mt={8}>
          <Button data-cy="closeAboutModalButton" onPress={overlayState.close}>
            <Label variant="l1">{t('About Modal Back Button')}</Label>
          </Button>
        </Flex>
      </Tray>
    </>
  );
};

export default AboutTrayButton;
