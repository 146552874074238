import React, { FC } from 'react';
import { useTextField } from 'react-aria';
import styled from 'styled-components';
import css from '@styled-system/css';
import { AriaTextFieldProps } from '@react-types/textfield';
import { Flex } from '../Primitives';
import { Label } from '../Typography';

const StyledInput = styled.input(
  css({
    border: '1px solid',
    borderColor: 'foreground',
    borderRadius: '4px',
    p: 2,
    fontFamily: 'body',
    fontSize: 4,
    color: 'foreground',
  }),
);

export interface InputProps extends AriaTextFieldProps {
  as?: string;
}

const Input: FC<InputProps> = props => {
  // TODO: Figure out why onChange was in here and ruining all the types
  const { label = 'label', as = 'input', onChange, ...restStyles } = props;
  const ref = React.useRef(null);

  // TODO: Label props can't be used with <p> (see Label component)
  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(props, ref);

  return (
    <Flex flexDirection="column" maxWidth="400px" {...restStyles}>
      <Label
        sx={{
          mb: 3,
        }}
        alignItems="initial"
        {...labelProps}
      >
        {label}
      </Label>
      <StyledInput {...inputProps} as={as} ref={ref} />
      {props.description && (
        <div {...descriptionProps} style={{ fontSize: 12 }}>
          {props.description}
        </div>
      )}
      {props.errorMessage && (
        <div {...errorMessageProps} style={{ color: 'red', fontSize: 12 }}>
          {props.errorMessage}
        </div>
      )}
    </Flex>
  );
};

export default Input;
