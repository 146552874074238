import gameConfig from '@content/gameconfig';

const windowSessionId = typeof window !== 'undefined' && window.session_id;

let cachedSessionObject: SessionData | null = null;

const pixelTracker = async ({
  collectionName,
  data,
}: {
  collectionName:
    | 'sessions'
    | 'answers'
    | 'corrections'
    | 'demographics'
    | 'events';
  data: Omit<LogData, 'collection_name'> | Omit<SessionData, 'collection_name'>;
}) => {
  const parameterizedData = window.parameterizeData(data);

  // the first ep is getting the raw end point for dev, and the alternative comes from netlify when in prod
  // on netlify it gets proxied to the actual endpoint (see netlify.toml)
  const pixelEp =
    process.env.NODE_ENV === 'development'
      ? process.env.GATSBY_PIXEL_URL
      : '/pixel';
  try {
    await fetch(
      `${pixelEp}?cn=${collectionName}&${window.encryptForPixel(
        parameterizedData,
      )}`,
    );
  } catch (e) {
    throw new Error(e);
  }
};

interface SessionData {
  // this id is only relevant with pixels, as it is part of the payload itself
  // it is transient
  session_id: string;
  // this id is stored locally to track repeats
  user_id: string;
  host: string;
  language: string;
  survey_name: string;
  // basically the hash, but internally we call this 'version'
  version: string;
  // to identify which code release we are on
  core_version: string;
  // this is subject to change as game continues (provided we are using profiler)
  stream_name: string;
  referrer: string;
}

export type OptionalSessionData = Partial<SessionData>;

/** initialize a unique session */
export const initSession = async (
  optSessionData: OptionalSessionData = {},
  // this is when we are using the pixel tracker and want to perform an update
  optSessionId: string | null = null,
): Promise<string | null> => {
  const streamTableNames = gameConfig.Stream_Table_Names;
  const refObj: SessionData = cachedSessionObject
    ? { ...cachedSessionObject, ...optSessionData }
    : {
        user_id: window.user_id,
        host: window?.location?.host,
        language:
          gameConfig.Languages_Used?.[0]?.Content_Type ||
          gameConfig.Languages_Used?.Content_Type,
        survey_name: gameConfig.Version,
        version: window.location.hash.replace('#', ''),
        core_version: `${gameConfig.coreVersion}${
          process.env.BRANCH ? `-${process.env.BRANCH}` : ''
        }`,
        referrer: document?.referrer || '',
        stream_name: Array.isArray(streamTableNames)
          ? streamTableNames[0]
          : streamTableNames,
        ...optSessionData,
      };
  cachedSessionObject = refObj;

  const sessionId = optSessionId || windowSessionId;

  try {
    // this might fail if CORS error or otherwise
    await pixelTracker({
      collectionName: 'sessions',
      data: {
        ...refObj,
        session_id: sessionId,
      },
    });
  } catch (e) {
    console.error('pixel tracker failed ----------------');
  }

  // TODO: graceful fallback if logging not init, maybe an alert
  console.info(
    '%c 🔎 For transparency, you can see all the data that we log below 🔎 ',
    'color: red',
  );
  console.info('LOG: ', refObj);
};

/** each entry in our db has these in common */
export interface CommonLogData {
  // this is optional, and is the name of the content type entry on airtable
  question_name?: string;
  // question_index: number;
  step_counter?: number;
}

export interface Answers {
  /* text is weird, we can say, this is more like the value (TODO: change name later) */
  answer_text: string;
  duration_in_seconds: number;
  // effectively the name that is supplied on airtable, it contains the type in there as well
  question_type: string;
  // the last option here is for when you are using this interface in claims, claims have no results
  result: 'correct' | 'incorrect' | '';
  collection_name: 'answers';
}

interface Correction {
  comments: string;
  source_url: string;
  collection_name: 'corrections';
}

interface Demographic {
  /** this can be an array as well because demo questions can have multiple options */
  answers: string[] | object;
  collection_name: 'demographics';
}

interface Event {
  event_type:
    | 'demo_screen_curtain'
    | 'click'
    | 'open_context_menu'
    | 'toggle_lang'
    | 'set_stream'
    | 'toggle_settings'
    | 'session_kicked'
    | 'rank_up'
    | 'rank_down'
    | 'consent'
    | 'reset_game'
    | 'finished_game'
    | 'error'
    | 'session_init_in_ms'
    | 'init_performance'
    | 'navigate';
  location?: string;
  /** a little more specific than just the question name, can be descriptive such as "help button" etc. */
  target: string | number;
  collection_name: 'events';
}

export type LogData = Answers | Correction | Demographic | Event;

export const log = async (
  data: LogData & CommonLogData,
  sessionId: string | null,
) => {
  if (!sessionId) {
    console.error('attempting to log item without a session id', data);
  }
  /** NOTE: why is this in prod? This represents transparency. Anyone can know what we intake.
   * This transparency is similar to seeing your debug log on a variety of apps and services
   */
  console.info('LOG: ', data);
  const { collection_name, ...dataMinusCollectionName } = data;

  await pixelTracker({
    collectionName: collection_name,
    data: {
      ...dataMinusCollectionName,
      session_id: sessionId || 'error',
    },
  });
};

export const batchLog = async (
  data: LogData[] & CommonLogData[],
  sessionId: string | null,
) => {
  if (!sessionId) {
    console.error('attempting to log item without a session id', data);
  }
  let promiseBatch: Promise<any>[] = [];
  data.map(datum => {
    const { collection_name, ...datumMinusCollectionName } = datum;
    console.info('LOG: ', datumMinusCollectionName);
    promiseBatch = [
      ...promiseBatch,
      pixelTracker({
        collectionName: collection_name,
        data: {
          ...datumMinusCollectionName,
          session_id: sessionId || 'error',
        },
      }),
    ];
  });
  await Promise.all(promiseBatch);
};

export const setLanguage = async (
  language: string,
  sessionId: string | null,
) => {
  // we cannot "update" our documents per-se, so we just create a new session obj
  await initSession(
    {
      ...cachedSessionObject,
      language,
    },
    sessionId,
  );
};

export const setStreamName = async (
  streamName: string,
  sessionId: string | null,
) => {
  await initSession(
    {
      ...cachedSessionObject,
      stream_name: streamName,
    },
    sessionId,
  );
};
